import React, { Component } from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import Img from 'components/Image';
import _get from 'lodash/get';
import WL, { WL_HIVEOS } from 'constants/whiteLabel';
import Calculator from 'components/Pricing/Calculator';
import Link from 'components/Link';
import PriceListPositions from 'components/Pricing/PriceListPositions';
import { LOCALE_LINKS } from 'constants/links';
import gs from 'pages/pages.module.css';
import s from 'pages/pricing/pricing.module.css';
import 'pages/customStyles.css';
import { injectIntl } from 'react-intl';
import styles from './priceList.module.css';

class PriceList extends Component {
  state = {
    display: 'block',
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    const { innerWidth } = window;

    if (innerWidth < 350 && this.state.display !== 'xsMobile') {
      this.setState(() => ({
      display: "xsMobile",
      }))
    } else if (innerWidth < 768 && this.state.display !== 'mobile') {
      this.setState(() => ({
      display: "mobile",
      }))
    } else if (innerWidth > 768 && innerWidth < 1100 && this.state.display !== 'tablet') {
      this.setState(() => ({
      display: "tablet",
      }))
    } else if (innerWidth > 1100 && this.state.display !== 'desktop') {
      this.setState(() => ({
      display: "desktop",
      }))
    }
  }

  render() {
    const { display } = this.state;

    const forumBillingLink = _get(LOCALE_LINKS, [this.props.intl.locale, 'forumBilling'], '');

    return (
      <section className={cx(gs.content,s.pricingContent)}>
        <div className={gs.wrapper}>
          <div className={s.pricingModels}>
            {
              <PriceListPositions
                hasSlider={display === 'mobile'}
              />
            }
          </div>
          <Calculator />

          {
            WL.key === WL_HIVEOS ? (
              <div className={cx(s.description, s.coinpayments)}>
                <FormattedMessage id="PriceList.caption4" defaultMessage="We accept only crypto. Payments processed by" />
                <div className={styles.coinText}>
                  &nbsp;
                  <Link external href="https://www.coinpayments.net/" className={cx(styles.coinText, styles.coinLink)}>
                    &quot;&quot;
                  </Link>
                  <Img height={36} width={117} src="coinpayments.png" styles={{ display: 'inline-block' }} alt="coinpayments" />
                </div>
              </div>
            ) : null
          }
          {
            WL.key === WL_HIVEOS ? (
              <div className={s.description}>
                <div className={styles.billingLink}>
                  <Link
                    external
                    noRef
                    href={forumBillingLink}
                    className={s.info}
                  >
                    <FormattedMessage id="PriceList.caption5" defaultMessage="Billing Explained" />
                    <span>i</span>
                  </Link>
                </div>
                <p className={styles.subheader}>
                  <FormattedMessage id="PriceList.caption6" defaultMessage="Free for testing and home mining. CPU rig costs only $0.3/mo" />
                </p>
                <p className={styles.subheader}>
                  <FormattedMessage id="PriceList.caption7" defaultMessage="Starting from 5 workers you <b>pay for all 5</b>" values={{ b: (...chunks) => <b>{chunks}</b> }} />
                </p>
                <p className={styles.subheader}>
                  <FormattedMessage id="PriceList.caption8" defaultMessage="Clone accounts will be detected and banned" />
                </p>
              </div>
            ) : null
          }
        </div>
      </section>
    );
  }

}

export default injectIntl(PriceList);
