import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import ExpandBtn from 'components/Pricing/ExpandBtn';
import WL from 'constants/whiteLabel';
import Img from 'components/Image';
import hexColoredSmall from 'utils/images/hex-colored-small.svg';
import gs from 'pages/pages.module.css';
import s from 'pages/homeStyles.module.css';
import style from './partnership.module.css';

const Partnership = () => (
  <section className={cx(gs.content, s.hiveosContent)}>
    {
        WL.showHexImg ? (
          <img alt="hex" className={cx(s.drop1, s.__oneSolution)} src={hexColoredSmall} />
        ) : null
    }
    <div className={gs.backgroundHeader}>partnership</div>
    <div className={cx(gs.wrapper, s.wrapper)}>
      <div className={gs.relative}>
        <div className={style.transfromToLeft}>
          <Img className={style.screen} src={WL.getRigImage()} alt="partnership" />
        </div>
      </div>
      <div className={style.text}>
        <h2><FormattedMessage id="Partnership.title" defaultMessage="Vendors and Retail Shops Partnership" /></h2>
        <p className={style.subheader}><FormattedMessage id="Partnership.caption1" defaultMessage="Integrate with Hive OS" /></p>
        <div>
          <FormattedMessage id="Partnership.caption2" defaultMessage="Manufacturers and custom hardware integrators can sign-up for our partner’s program. Integrate and sell your hardware systems with preinstalled Hive OS and get 10% for each worker joining our mining platform through your referral ID." />
        </div>
        <div><b><FormattedMessage id="Partnership.caption3" defaultMessage="Sounds interesting? Then contact us via {email}" values={{ email: WL.getBusinessEmail() }} /></b></div>
        <div className={style.btn}>
          {
            WL.salesBtnType === 'button' ? (
              <a href={`mailto:${WL.getBusinessEmail()}`} className="btn-primary-simple">
                <FormattedMessage id="Partnership.contactUs" defaultMessage="Contact Us" />
              </a>
            ) : null
          }
          {
            WL.salesBtnType === 'qr' ? (
              <ExpandBtn title={'Partnership.contactUs'}>
                <img src={WL.salesBtnQr} alt="QR" />
              </ExpandBtn>
            ) : null
          }
        </div>
      </div>
    </div>
  </section>
)

export default Partnership;
