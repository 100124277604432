import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import Slider from 'components/slider/slider';
import hexWhiteTiny from 'utils/images/hex-white-tiny.svg';
import WL, { WL_BIGMINER, WL_HIVEOS } from 'constants/whiteLabel';
import ExpandBtn from 'components/Pricing/ExpandBtn';
import Link from 'components/Link';
import s from 'pages/pricing/pricing.module.css';
import gs from 'pages/pages.module.css';
import 'pages/customStyles.css';
import styles from './priceListPositions.module.css';

class PriceListPositions extends Component {
  getItemsHiveos() {
    return [
      <div key={'startPrice'} className={s.pricingModel}>
        <div className={s.pricingTitle}>
          <FormattedMessage id="PriceList.start" defaultMessage="Start" />
        </div>
        <div className={s.price}>
          $0<span className={s.small}>/<FormattedMessage id="PriceList.mo" defaultMessage="mo" /></span>
        </div>
        <div className={s.descr}>
          <span className={styles.ml6}>
            <FormattedMessage id="PriceList.limitedTo" defaultMessage="Limited to " />
          </span>
          <Link
            external
            href="https://medium.com/hiveon/hiveon-pool-important-updates-cd4f1be00f0a"
            className={cx(styles.orange, gs.link)}
          >
            4 <FormattedMessage id="PriceList.workers" defaultMessage="workers" />*
          </Link>
        </div>
        <div className={styles.smallDescr}>
          * <FormattedMessage id="PriceList.caption1" defaultMessage="mining Ethereum / Ethereum Classic on a non-Hiveon Pool costs $3 per rig" />
        </div>
        <div className={styles.smallDescr}>
          * <FormattedMessage id="PriceList.caption2" defaultMessage="1 worker is always free" />
        </div>
        <hr/>

        {[
          <FormattedMessage id="PriceList.start_text1" defaultMessage="Limited to 4 workers" key="Limited to 4 workers" />,
          <FormattedMessage id="PriceList.start_text2" defaultMessage="Limited features" key="Limited features" />,
          <FormattedMessage id="PriceList.start_text3" defaultMessage="3 days of stats" key="3 days of stats" />,
          <FormattedMessage id="PriceList.start_text4" defaultMessage="Community support" key="Community support" />,
        ].map((item, index) => (
          <div key={index} className={s.pricingFeature}>
            <img alt="hex white" src={hexWhiteTiny} />
            {item}
          </div>
        ))}

        <div className={s.btnContainer}>
          <Link external noRef href={WL.getRegisterLink()} className="btn-primary-simple" target={WL.regLinkTarget}>
            <FormattedMessage id="PriceList.join_now" defaultMessage="Join now" />
          </Link>
        </div>
      </div>,

      <div key={'personalPrice'} className={cx(s.pricingModel, s.primary)}>
        <div className={s.pricingTitle}>
          <FormattedMessage id="PriceList.personal" defaultMessage="Personal" />
        </div>
        <div className={s.price}>
          $3<span className={s.small}>/<FormattedMessage id="PriceList.mo_per_rig" defaultMessage="mo per rig" /></span>
        </div>
        <div className={styles.pricingFeatureNote}>
          <FormattedMessage
            id="PriceList.or_with_pool"
            defaultMessage="or <b>3%</b> with {poolLink}"
            values={
              {
                b: (...chunks) => <b>{chunks}</b>,
                poolLink: <Link external href="https://medium.com/hiveon/hiveon-pool-important-updates-cd4f1be00f0a"><FormattedMessage id="PriceList.hiveonPool" defaultMessage="Hiveon Pool" /></Link>
              }
            }
          />
        </div>
        <hr className={s.separator}/>
        <div className={s.price}>
          $2<span className={s.small}>/<FormattedMessage id="PriceList.mo_per_asic" defaultMessage="mo per asic" /></span>
        </div>

        <div className={styles.pricingFeatureNote}>
          <FormattedMessage
            id="PriceList.or_with_asic"
            defaultMessage="or <b>FREE</b> with {asicLink}"
            values={
              {
                b: (...chunks) => <b>{chunks}</b>,
                asicLink: <Link withLang to="/asic"><FormattedMessage id="PriceList.hiveonAsic" defaultMessage="Hiveon ASIC" /></Link>
              }
            }
          />
        </div>

        <hr/>

        {[
          <FormattedMessage id="PriceList.personal_text1" defaultMessage="All features" key="All features" />,
          <FormattedMessage id="PriceList.personal_text2" defaultMessage="More than 4 workers" key="ore than 4 workers" />,
          <FormattedMessage id="PriceList.personal_text3" defaultMessage="30 days of stats" key="30 days of stats" />,
          <FormattedMessage id="PriceList.personal_text4" defaultMessage="SSL connection for workers" key="SSL connection for workers" />,
          <FormattedMessage id="PriceList.personal_text5" defaultMessage="Multiple farms management" key="Multiple farms management" />,
          <FormattedMessage id="PriceList.personal_text6" defaultMessage="Email & Community support" key="Email & Community support" />,
        ].map((item, index) => (
          <div key={index} className={s.pricingFeature}>
            <img alt="hex white" src={hexWhiteTiny} />
            {item}
          </div>
        ))}

        <div className={s.btnContainer}>
          <Link external noRef href={WL.getRegisterLink()} className="btn-primary-big" target={WL.regLinkTarget}>
            <FormattedMessage id="PriceList.createAccount" defaultMessage="Create Account" />
          </Link>
        </div>
      </div>,
      <div key={'customPrice'} className={s.pricingModel}>
        <div className={s.pricingTitle}>
          <FormattedMessage id="PriceList.business" defaultMessage="Business" />
        </div>
        <div className={s.price}>
          <FormattedMessage id="PriceList.business_caption" defaultMessage="custom" />
        </div>
        <div className={s.descr}>
          <FormattedMessage id="PriceList.caption3" defaultMessage="Available with 500 rigs and more" />
        </div>
        <hr/>
        {[
          <FormattedMessage id="PriceList.business_text1" defaultMessage="All regular features" key="All regular features" />,
          <FormattedMessage id="PriceList.business_text3" defaultMessage="Additional individual features" key="Additional individual features" />,
          <FormattedMessage id="PriceList.business_text4" defaultMessage="An opportunity to subscribe to paid support service" key="An opportunity to subscribe" />,
          <FormattedMessage id="PriceList.business_text5" defaultMessage="Private proxy channels for a more reliable access" key="Private proxy channels" />,
          <FormattedMessage id="PriceList.business_text7" defaultMessage="Individual billing" key="Individual billing" />,
          <FormattedMessage id="PriceList.business_text8" defaultMessage="Individual support" key="Individual support" />
        ].map((text, idx) => (
          <div className={s.pricingFeature} key={idx}>
            <img alt="hex white" src={hexWhiteTiny} />
            {text}
          </div>
        ))}
        <div className={s.btnContainer}>
          <a href="mailto:brain@hiveos.farm" className="btn-primary-simple">
            <FormattedMessage id="PriceList.contactSales" defaultMessage="Contact Sales" />
          </a>
        </div>
      </div>
    ];
  }

  getItemsBigminer() {
    return [
      <div key={'startPrice'} className={s.pricingModel}>
        <div className={s.pricingTitle}>
          <FormattedMessage id="PriceList.timeLimitedVersion" defaultMessage="Time limited version" />
        </div>
        <div className={s.price}>
          ￥0
        </div>
        <div className={s.descr}>
          <FormattedMessage
            id="PriceList.limitedTo5workersinclusive"
            defaultMessage="Limited to 5 workers (inclusive)"
          />
        </div>

        <hr/>

        {[
          <FormattedMessage id="PriceList.freeFor7daysFor5Workers" defaultMessage="Free 7 days for 5 workers" key="Free 7 days for 5 workers" />,
          <FormattedMessage id="PriceList.startAlternative.text2" defaultMessage="Limited functionality" key="Limited functionality" />,
          <FormattedMessage id="PriceList.startAlternative.text3" defaultMessage="3 days of statistics" key="3 days of statistics" />,
          <FormattedMessage id="PriceList.start_text4" defaultMessage="Community support" key="Community support" />,
        ].map((item, index) => (
          <div key={index} className={s.pricingFeature}>
            <img alt="hex white" src={hexWhiteTiny} />
            {item}
          </div>
        ))}

        <div className={s.btnContainer}>
          <Link external noRef href={WL.getRegisterLink()} className="btn-primary-simple" target={WL.regLinkTarget}>
            <FormattedMessage id="PriceList.join_now" defaultMessage="Join now" />
          </Link>
        </div>
      </div>,
      <div key={'personalPrice'} className={cx(s.pricingModel, s.primary)}>
        <div className={s.pricingTitle}>
          <FormattedMessage id="PriceList.personal" defaultMessage="Personal" />
        </div>

          <div className={s.price}>
            1%<span className={s.small}> <FormattedMessage id="PriceList.serviceFee" defaultMessage="Service Fee" /></span>
          </div>

          <div className={styles.textCenter}>
            <FormattedMessage id="PriceList.fiveUpWorkers" defaultMessage="More than 5 workers" />
          </div>

        <hr/>

        {[
          <FormattedMessage id="PriceList.personalAlternative.text1" defaultMessage="More than 5 workers" key="More than 5 workers" />,
          <FormattedMessage id="PriceList.personalAlternative.text2" defaultMessage="All functions" key="All functions" />,
          <FormattedMessage id="PriceList.personalAlternative.text3" defaultMessage="30 days of stats" key="30 days of stats" />,
          <FormattedMessage id="PriceList.personalAlternative.text4" defaultMessage="SSL connection for workers" key="SSL connection for workers" />,
          <FormattedMessage id="PriceList.personalAlternative.text5" defaultMessage="Multiple farms management" key="Multiple farms management" />,
          <FormattedMessage id="PriceList.personalAlternative.text6" defaultMessage="Email & Community support" key="Email & Community support" />,
        ].map((item, index) => (
          <div key={index} className={s.pricingFeature}>
            <img alt="hex white" src={hexWhiteTiny} />
            {item}
          </div>
        ))}

        <div className={s.btnContainer}>
          <Link external noRef href={WL.getRegisterLink()} className="btn-primary-big" target={WL.regLinkTarget}>
            <FormattedMessage id="PriceList.createAccount" defaultMessage="Create Account" />
          </Link>
        </div>
      </div>,
      <div key={'customPrice'} className={s.pricingModel}>
        <div className={s.pricingTitle}>
          <FormattedMessage id="PriceList.business.enterprise" defaultMessage="Enterprise" />
        </div>
        <div className={s.price}>
          <FormattedMessage id="PriceList.business.enterpriseCaptions" defaultMessage="custom" />
        </div>
        <div className={s.descr}>
          <FormattedMessage id="PriceList.custom100G" defaultMessage="100G and more" />
        </div>
        <hr/>

        {[
          <FormattedMessage id="PriceList.businessAlternative.text5" defaultMessage="Discount" key="Discount" />,
          <FormattedMessage id="PriceList.businessAlternative.text0" defaultMessage="Provide in-depth optimization parameters" key="Provide in-depth optimization parameters" />,
          <FormattedMessage id="PriceList.businessAlternative.text1" defaultMessage="Technical Training" key="Technical Training" />,
          <FormattedMessage id="PriceList.businessAlternative.text2" defaultMessage="One-to-one technical service" key="One-to-one technical service" />,
          <FormattedMessage id="PriceList.businessAlternative.text3" defaultMessage="Private server" key="Private server" />,
          <FormattedMessage id="PriceList.businessAlternative.text4" defaultMessage="Functions customization" key="Functions customization" />,
        ].map((text, idx) => (
          <div className={s.pricingFeature} key={idx}>
            <img alt="hex white" src={hexWhiteTiny} />
            {text}
          </div>
        ))}
        <div className={s.btnContainer}>
          <ExpandBtn title="PriceList.contactSalesAlternative">
            <img src={WL.pricingBtnQr} alt="QR" />
          </ExpandBtn>
        </div>
      </div>
    ];
  }

  getItems() {
    if (WL.key === WL_HIVEOS) {
      return this.getItemsHiveos();
    } else if (WL.key === WL_BIGMINER) {
      return this.getItemsBigminer();
    }

    return null;
  }

  render() {
    const { hasSlider } = this.props;

    let optionsSlider = {
      adaptiveHeight: false,
      centerMode: true,
      slidesToScroll: 1,
      centerPadding: "20px",
      variableWidth: true,
      focusOnSelect: true,
      dots: true,
      initialSlide: 1,
    };

    return (
      <Fragment>
        {
          hasSlider
          ? <Slider options={optionsSlider}>{this.getItems()}</Slider>
          : this.getItems()
        }
      </Fragment>
    );
  }

}

export default PriceListPositions;
