import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import _get from 'lodash/get';
import Layout from 'components/layout';
import Hero from 'components/Layout/Hero';
import Discounts from 'components/Pricing/Discounts';
import CustomPrices from 'components/Pricing/CustomPrices';
import Bonus from 'components/Pricing/Bonus';
import Referral from 'components/Pricing/Referral';
import Partnership from 'components/Pricing/Partnership';
import PriceList from 'components/Pricing/PriceList';
import GetStarted from 'components/GetStarted';
import WL, { WL_BIGMINER } from 'constants/whiteLabel';

import Bottom from 'components/Bottom/Bottom';

const SecondPage = props => (
  <Layout page="pricing" lang={_get(props, 'pageContext.lang', 'en')}>
    <Hero headerIntl={<FormattedMessage id="pricing" defaultMessage="Pricing" />}>
      <PriceList/>
    </Hero>
    <div>
      <Discounts />

      {
        WL.key !== WL_BIGMINER ? (
          <Fragment>
            <Bonus />
            <Referral />
            <Partnership />
          </Fragment>
        ) : (
          <CustomPrices />
        )
      }
      <GetStarted />
      <Bottom />
    </div>
  </Layout>
)


export default SecondPage;
