import React, { useState } from 'react';
import cx from 'classnames'
import { FormattedMessage } from 'react-intl';
import style from './expandbtn.module.css';

const ExpandBtn = (props) => {
  const [isOpen, setIsOpen] = useState(null);

  const {
    children,
    title,
  } = props;

  return (
    <div className={style.root}>
      <span
        className={cx("btn-primary-simple", style.btn)}
        onClick={() => setIsOpen(!isOpen)}
        role="presentation"
      >
        <FormattedMessage id={title} />
      </span>

      <span className={cx(style.slider ,{
          [style.slideNone]: (isOpen === null),
          [style.slideIn]: (!isOpen && isOpen !== null),
          [style.slideOut]: (isOpen && isOpen !== null)
        })}>
          {children}
        </span>
    </div>
  );
}

export default ExpandBtn;
