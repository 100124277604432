import 'rc-slider/assets/index.css';
import 'components/Pricing/Calculator/slider.css';

import React, { Component } from 'react';
import cx from 'classnames';
import Slider from 'rc-slider';
import Link from 'components/Link';
import sliderBg from 'utils/images/slider_bg.png';
import s from './Calculator.module.css';

const trackStyle = {
  background: '#F2BB40',
};

const railStyle = {
  backgroundImage: `url(${sliderBg})`,
  backgroundColor: 'rgba(0, 0, 0, 0)',
  backgroundRepeat: 'repeat-x',
  backgroundPosition: 'right top',
}

const dotStyle = {
  'background': 'transparent',
  border: 'none'
}

const handleStyle = {
  backgroundColor: '#F2BB40',
  border: '0 !important'
}

const MAX_COUNT = 999999;
const ASIC_COST = 2;
const GPU_COST = 3;
const FREE_WORKERS_COUNT = 4;

const Handle = Slider.Handle;

const handle = props => {
  const { value, index, ...restProps } = props;

  let label = '0 %';
  if (value >= 2) {
    label = '30 %';
  }

  return (
    <div key={index}>
      <Handle value={value} {...restProps} />
      <div
        className={cx(s.tooltip, { [s.isHidden]: value < 2 })}
        style={{ left: props.offset * 1.90 - 21 }}
      >
        {label}
      </div>
    </div>
  );
};

class Calculator extends Component {
  state = {
    gpu: 25,
    asic: 55,
    slider: 2,
  };

  handleChange = slider => {
    this.setState({ slider });
  }

  handleAsicChange = e => {
    this.setState({ asic: e.target.value });
  }

  handleGpuChange = e => {
    this.setState({ gpu: e.target.value });
  }

  getDiscount(val) {
    let discount = 1;

    if (val >= 50) {
      discount -= 0.1;
    }

    if (val >= 100) {
      discount -= 0.1;
    }

    if (val >= 250) {
      discount -= 0.1;
    }

    if (val >= 500) {
      discount -= 0.1;
    }

    if (val >= 1000) {
      discount -= 0.1;
    }

    return discount;
  }

  getDevfee() {
    const { gpu } = this.state;

    let devfee = 3;

    if (gpu >= 50) {
      devfee = 2.7;
    }

    if (gpu >= 100) {
      devfee = 2.4;
    }

    if (gpu >= 250) {
      devfee = 2.1;
    }

    if (gpu >= 500) {
      devfee = 1.8;
    }

    if (gpu >= 1000) {
      devfee = 1.5;
    }

    return devfee;
  }

  render() {
    const { gpu, asic, slider } = this.state;

    let total = '-';
    let withDiscount = '-';
    let one = '-';
    let gpuVal = parseInt(gpu, 0) || 0;
    let asicVal = parseInt(asic, 0) || 0;

    if (gpuVal >= 0 && asicVal >= 0 && gpuVal <= MAX_COUNT && asicVal <= MAX_COUNT) {
      const asicDiscount = this.getDiscount(asicVal);
      const gpuDiscount = this.getDiscount(gpuVal);

      let sliderDiscount = 1;

      if (slider >= 2) {
        sliderDiscount -= 0.3;
      }

      total = asicVal * ASIC_COST + gpuVal * GPU_COST;

      if (asicVal + gpuVal <= FREE_WORKERS_COUNT) {
        withDiscount = 0;
      } else {
        withDiscount = Math.round(
          (asicVal * ASIC_COST * asicDiscount
          + gpuVal * GPU_COST * gpuDiscount)
          * sliderDiscount
          * 100
        ) / 100;
      }

      one = gpuVal + asicVal > 0
        ? Math.ceil(withDiscount / (gpuVal + asicVal) * 100) / 100
        : 0;
    }

    return (
      <section className={s.root}>
        <h2>{`Let's calculate`}</h2>
        <div className={s.card}>
          <div className={s.row}>
            <div className={cx(s.col, s.padding)}>
              <input
                className={s.input}
                value={asic}
                onChange={this.handleAsicChange}
                type="number"
                min={0}
                max={20000}
              />
              { /* eslint-disable-next-line */ }
              <label className={s.label}>ASIC</label>
            </div>
            <div className={cx(s.col, s.padding)}>
              <input
                className={s.input}
                value={gpu}
                onChange={this.handleGpuChange}
                type="number"
                min={0}
                max={20000}
              />
              { /* eslint-disable-next-line */ }
              <label className={s.label}>GPU</label>
            </div>
            <div className={cx(s.col, s.padding)}>
              <div className={s.sliderContainer}>
                <Slider
                  min={0}
                  max={6}
                  value={slider}
                  onChange={this.handleChange}
                  marks={['1 month', '', '3', '', '6', '', '12']}
                  handle={handle}
                  trackStyle={trackStyle}
                  railStyle={railStyle}
                  dotStyle={dotStyle}
                  handleStyle={handleStyle}
                />
              { /* eslint-disable-next-line */ }
              <label className={s.labelSlider}>Deposit discount</label>
              </div>
            </div>
          </div>
          <div className={s.tableTitleRow}>
            <div className={s.tableCol}>
              TOTAL COST/MO
            </div>
            <div className={s.tableCol} style={{ 'textAlign': 'center' }}>
              COST WITH DISCOUNT
            </div>
            <div className={s.tableCol} style={{ 'textAlign': 'right' }}>
              1 WORKER COST
            </div>
          </div>
          <div className={s.row}>
            <div className={s.tableColMob}>
              TOTAL COST/MO
            </div>
            <div className={cx(s.tableColVal, s.flex, s.marginAuto)}>
              <div className={s.total}>
                $ {total}
                <div className={cx(s.cross, {[s.isHidden]: total === withDiscount})} />
              </div>
              <div
                className={cx(
                  s.discountTooltip,
                  {[s.isHidden]: total === withDiscount}
                )}
              >
                save ${Math.ceil(total - withDiscount)}
              </div>
            </div>
            <div className={s.tableColMob}>
              COST WITH DISCOUNT
            </div>
            <div className={cx(s.tableColVal, s.alignCenter)}>
              $ {withDiscount}
            </div>
            <div className={s.tableColMob}>
              1 WORKER COST
            </div>
            <div className={cx(s.tableColVal, s.alignRight)}>
              $ {one}
            </div>
          </div>
          <div className={cx(s.row, s.margin1)}>
            <div className={cx(s.col, s.subText)}>
              <a href="https://hiveos.farm/faq-billing_in_hive_os-billing#Paying-for-the-system-commission-on-the-Hiveon-pool">
                Pay for Hive OS on Hiveon Pool
              </a>
            </div>
            <div className={cx(s.tableColVal, s.alignRight, s.orange)}>
              $0 + {this.getDevfee()}% devfee
            </div>
          </div>
          <div className={cx(s.row, s.margin)}>
            <div className={cx(s.col, s.subText)}>
              <Link to="/asic/">With Hiveon ASIC Firmware</Link>
            </div>
            <div className={cx(s.tableColVal, s.alignRight, s.orange)}>
              0
            </div>
          </div>
          <div className={cx(s.row, s.margin, s.small)}>
             * More details about bonuses and discounts you can read below
          </div>
        </div>
      </section>
    );
  }
}

export default Calculator;
