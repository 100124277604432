import React from 'react';
import cx from 'classnames'
import { FormattedMessage } from 'react-intl';
import styles from 'pages/pages.module.css'
import pricingStyles from 'pages/pricing/pricing.module.css';

const pricingTable = [
    { workers: '< 100', discount: '0%', pricing: '20' },
    { workers: '100 +', discount: '5%', pricing: '19' },
    { workers: '500 +', discount: '10%', pricing: '18' },
    { workers: '1000 +', discount: '20%', pricing: '16' },
    { workers: '2500 +', discount: '30%', pricing: '14' },
    { workers: '5000 +', discount: '40%', pricing: '12' },
    { workers: '10000 +', discount: '50%', pricing: '10' },
];

const CustomPrices = () => (
    <section className={cx(styles.content, pricingStyles.discounts)}>
        <div className={styles.wrapper}>
            <h2><FormattedMessage id="CustomPrices.title" defaultMessage="Custom user" /></h2>
            <div className={cx(pricingStyles.list, pricingStyles.listFullwidth)}>
                <div className={pricingStyles.row}>
                    <div className={pricingStyles.tableCellLeft}>
                        <FormattedMessage id="CustomPrices.ladder" defaultMessage="Ladder" />
                    </div>
                    <div className={pricingStyles.tableCell}>
                        <FormattedMessage id="CustomPrices.discount" defaultMessage="Discount" />
                    </div>
                    <div className={pricingStyles.tableCell}>
                        <FormattedMessage id="CustomPrices.customPrice" defaultMessage="Custom pricing" />
                    </div>
                </div>
                {
                    pricingTable.map(i => (
                        <div className={pricingStyles.row} key={i.workers}>
                            <div className={pricingStyles.tableCellLeft}>
                                {i.workers}
                            </div>
                            <div className={pricingStyles.tableCell}>
                                {i.discount}
                            </div>
                            <div className={pricingStyles.tableCell}>
                                {i.pricing}
                            </div>
                        </div>
                    ))
                }
                <div className={pricingStyles.row}>
                    <div className={pricingStyles.tableCellLeft}>
                        
                    </div>
                    <div className={pricingStyles.tableCell}>
                        
                    </div>
                    <div className={pricingStyles.tableCell}>
                        <FormattedMessage id="CustomPrices.units" defaultMessage="*(Yuan/Unit/Month)" />
                    </div>
                </div>
            </div>
        </div>
    </section>
);

export default CustomPrices;