import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import WL from 'constants/whiteLabel';
import hexColoredSmall from "utils/images/hex-colored-small.svg"
import gs from 'pages/pages.module.css';
import s from 'pages/pricing/pricing.module.css';
import Link from 'components/Link';
import style from './referral.module.css';

const Referral = () => (
    <section id="referal" className={cx(gs.content, s.alternate, s.referal)}>
        {
            WL.showHexImg ? (
              <img alt="hex" className={s.drop1} src={hexColoredSmall} />
            ) : null
        }
        <div className={gs.backgroundHeader}>referral</div>
        <div className={gs.wrapper}>
          <div className={style.tenPercents}>
            10 %
          </div>
          <div className={style.textBlock}>
            <h2><FormattedMessage id="Referral.title" defaultMessage="Referral Rewards System" /></h2>
            <h4><FormattedMessage id="Referral.caption1" defaultMessage="Earn from your referrals payments" /></h4>
            <div>
              <FormattedMessage id="Referral.caption2" defaultMessage="Sign-up and get a referral link with your account ID. Share it on social networks, forums or blogs. New users that sign-up using your referral link will be marked as your referrals. You can also create promo codes for new user to receive on their account." />
            </div>
            <div className={style.btn}>
              <Link withLang to="/referral" className="btn-primary-simple" target={WL.regLinkTarget}>
                <FormattedMessage id="Referral.register_earn" defaultMessage="Register and Earn" />
              </Link>
            </div>
          </div>
        </div>
    </section>
)

export default Referral;
