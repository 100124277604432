export const LOCALE_LINKS = new Proxy({
  'en': {
    'forumBilling': 'https://forum.hiveos.farm/t/billing/6916/1',
  },
  'ru': {
    'forumBilling': 'https://forum.hiveos.farm/t/billing/6916/3',
  },
}, {
  get: function(target, property) {
    for (let k in target) {
      if (k === property) {
        return target[k];
      }
    }
    return target['en'];
  }
});