import React, { Component } from 'react';
import SlickSlider from 'react-slick';
import styles from "./slider.module.css";
import "slick-carousel/slick/slick-theme.css";
import "./slickStyles.css";

class slide extends Component {
  componentDidMount() {
    if (typeof(window) !== 'undefined') {
      window.addEventListener('touchstart', this.touchStart);
      window.addEventListener('touchmove', this.preventTouch, { passive: false });
    }
  }

  componentWillUnmount() {
    if (typeof(window) !== 'undefined') {
      window.removeEventListener('touchstart', this.touchStart);
      window.removeEventListener('touchmove', this.preventTouch, { passive: false });
    }
  }

  touchStart(e){
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  }

  preventTouch(e){
    const minValue = 5; // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX;
    this.clientY = e.touches[0].clientY - this.firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if (Math.abs(this.clientX) > minValue && e.cancelable){
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  }

  render() {
    const options = {
      className: styles.slider,
      adaptiveHeight: true,
      dots: false,
      infinite: false,
      arrows: false,
      ...this.props.options
    };

    return (
     <SlickSlider {...options} style={{width: '100%'}}>
       {this.props.children.map((item, index) => (
         <div key={'slide '+index} className={styles.slide}>{item}</div>
       ))}
     </SlickSlider>
    );
  }
}

export default slide;
