import React from 'react';
import cx from 'classnames'
import { FormattedMessage } from 'react-intl';
import Link from 'components/Link';
import hexColoredSmall from "utils/images/hex-colored-small.svg"
import WL from 'constants/whiteLabel';
import gs from 'pages/pages.module.css';
import s from 'pages/pricing/pricing.module.css';
import style from './bonus.module.css';

const Bonus = () => (
  <section id="bonus" className={cx(gs.content, s.alternate, s.referal)}>
    {
        WL.showHexImg ? (
          <img alt="hex" className={s.drop1} src={hexColoredSmall} />
        ) : null
    }
    <div className={gs.backgroundHeader}>bonus</div>
    <div className={style.wrapper}>
      <div className={style.percentsBlock}>
        <div className={style.percents}>
          30 %
        </div>
      </div>
      <div className={style.textBlock}>
        <h2><FormattedMessage id="Bonus.title" defaultMessage="Bonuses" /></h2>
        <div>
          <FormattedMessage id="Bonus.caption1" defaultMessage="Deposit 3 month worth of funds to your account and get an additional" />
          <Link
            external
            href="https://medium.com/hiveon/30-deposit-bonus-all-you-need-to-know-ee3838027cd3"
            className={gs.link}
          >{' '}
            30% <FormattedMessage id="Bonus.as_bonus" defaultMessage="as a bonus" />
          </Link>
          <br/>
          <FormattedMessage id="Bonus.caption2" defaultMessage="Use paid features during the last 2 weeks and grab such pleasant benefit." />
          <br/>
          <FormattedMessage id="Bonus.caption3" defaultMessage='Check out "Your funds" in the account to find out details.' />
          <br/><br/>
          ⚡ <FormattedMessage id="Bonus.caption4" defaultMessage="Get +3% bonus for any deposit in USDC, USDT, TUSD!" />
        </div>
      </div>
    </div>
  </section>
)

export default Bonus;
